import Loader from "apollo-react/components/Loader";
import { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import AppFooter from "./components/AppFooter/AppFooter";
import AppHeader from "./components/AppHeader/AppHeader";
import SDAFailureScreen from "./components/SDAFailure";
import Logout from "./pages/Logout/Logout";
import { userLogOut } from "./services/ApiServices";
import { getUserId, setIdleLogout } from "./utils";

const DynamicProducts = lazy(() =>
  import("./pages/DynamicProducts/DynamicProducts")
);
const LaunchPad = lazy(() => import("./pages/LaunchPad/LaunchPad"));
const StudySetup = lazy(() => import("./pages/StudySetup/StudySetup"));
const SystemSetup = lazy(() => import("./pages/SystemSetup/SystemSetup"));
const TenantSetup = lazy(() =>
  import("./pages/TenantSetup/ListTenants/TenantSetup")
);
const ServiceAccountSetup = lazy(() =>
  import("./pages/ServiceAcccount/ListServiceAccounts/ServiceAccountSetup")
);
const CreateServiceAccount = lazy(() =>
  import("./pages/ServiceAcccount/Create/CreateServiceAccount")
);
const UpdateServiceAccount = lazy(() =>
  import("./pages/ServiceAcccount/Update/UpdateServiceAccount")
);
const ImportWithUsers = lazy(() =>
  import("./pages/OnboardStudy/OnboardStudyWithUsers")
);
const CreateAssignSystem = lazy(() =>
  import("./pages/OnboardSystem/OnboardSystemWithUsers")
);
const ExistingProtocol = lazy(() =>
  import("./pages/OnboardStudy/AddProtocol/ExistingStudy")
);
const ExistingSystemAssignment = lazy(() =>
  import("./pages/SystemSetup/ExistingSystemStudies")
);
const PolicyList = lazy(() =>
  import("./pages/Admin/Policy/ListPolicy/PolicyList")
);
const CreatePolicy = lazy(() =>
  import("./pages/Admin/Policy/CreatePolicy/CreatePolicy")
);
const UpdatePolicy = lazy(() =>
  import("./pages/Admin/Policy/UpdatePolicy/UpdatePolicy")
);
const ListRoles = lazy(() => import("./pages/Admin/Role/ListRoles/index"));
const CreateRole = lazy(() =>
  import("./pages/Admin/Role/CreateRole/CreateRole")
);
const UpdateRole = lazy(() =>
  import("./pages/Admin/Role/UpdateRole/UpdateRole")
);
const VendorList = lazy(() =>
  import("./pages/Admin/Vendor/VendorList/VendorList")
);
const CreateVendor = lazy(() =>
  import("./pages/Admin/Vendor/CreateVendor/CreateVendor")
);
const CreateTenant = lazy(() =>
  import("./pages/TenantSetup/AddTenant/CreateTenant")
);
const ListUsers = lazy(() => import("./pages/UserManagement/ListUsers/index"));
const AddUser = lazy(() => import("./pages/UserManagement/AddUser/index"));
const UpdateUser = lazy(() =>
  import("./pages/UserManagement/UpdateUser/UpdateUser")
);
const ObjectLevelSecurity = lazy(() =>
  import("./pages/Admin/ObjectLevelSecurity/ObjectLevelSecurity")
);
const RowLevelSecurity = lazy(() =>
  import("./pages/Admin/RowLevelSecurity/RowLevelSecurity")
);
const SuggestedTables = lazy(() =>
  import("./pages/Admin/RowLevelSecurity/SuggestedTables")
);
const GroupManagement = lazy(() =>
  import("./pages/GroupManagement/ListGroups/GroupListing")
);
const AddNewGroup = lazy(() =>
  import("./pages/GroupManagement/AddNewGroup/AddNewGroup")
);
const EditGroup = lazy(() =>
  import("./pages/GroupManagement/EditGroups/EditGroup")
);
const UserAccess = lazy(() => import("./pages/UserAccess/UserAccess"));
const Empty = () => <></>;

const RoutesWrapper = () => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [checkedOnce, setCheckedOnce] = useState(false);
  const [isFooterRequired, setIsFooterRequired] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const currentPath = localStorage.getItem("currentPath");

  useEffect(() => {
    const userId = getUserId(true);
    if (userId) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [history]);

  useEffect(() => {
    const userId = getUserId(true);
    if (userId) {
      if (location.pathname === "/checkAuthentication") {
        history.push(`/launchpad`);
      }
      history.push(location.pathname);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (currentPath !== location.pathname) {
        if (!checkedOnce) {
          window.location.href = `${process.env.REACT_APP_LAUNCH_URL}`;
          setCheckedOnce(true);
        }
        localStorage.setItem("currentPath", location?.pathname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedOnce, history]);

  const logoutOnIdle = async () => {
    const isLogout = await userLogOut();
    if (isLogout) {
      setLoggedIn(false);
      history.push("/logout");
    }
  };

  useEffect(() => {
    setIdleLogout(() => {
      logoutOnIdle();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<Loader isInner />}>
      {loggedIn ? (
        <div className="page-wrapper">
          <AppHeader setLoggedIn={setLoggedIn} />
          <Switch>
            <Route
              path="/launchpad"
              exact
              render={() => (
                <LaunchPad setIsFooterRequired={setIsFooterRequired} />
              )}
            />
            <Route
              path="/cdi"
              exact
              render={() => <Redirect to="/launchpad" />}
            />
            <Route path="/study-setup" exact render={() => <StudySetup />} />
            <Route path="/system-setup" exact render={() => <SystemSetup />} />
            <Route
              path="/tenant-management"
              exact
              render={() => <TenantSetup />}
            />
            <Route
              path="/service-account-setup/list"
              exact
              render={() => <ServiceAccountSetup />}
            />
            <Route
              path="/service-account-setup/create-account"
              exact
              render={() => <CreateServiceAccount />}
            />
            <Route
              path="/service-account-setup/edit/:id"
              exact
              render={() => <UpdateServiceAccount />}
            />
            <Route
              path="/ExistingStudyAssignment"
              exact
              render={() => <ExistingProtocol />}
            />
            <Route
              path="/ExistingSystemAssignment"
              exact
              render={() => <ExistingSystemAssignment />}
            />
            <Route
              path="/ExistingProtocol"
              exact
              render={() => <ExistingProtocol />}
            />
            <Route path="/cdm" exact render={() => <DynamicProducts />} />
            <Route path="/cdr" exact render={() => <DynamicProducts />} />
            <Route path="/ca" exact render={() => <DynamicProducts />} />
            <Route path="/dsw" exact render={() => <DynamicProducts />} />
            <Route path="/vendor/list" exact render={() => <VendorList />} />
            <Route
              path="/profile/:name"
              exact
              render={() => (
                <UpdateUser setIsFooterRequired={setIsFooterRequired} />
              )}
            />

            <Route
              path="/analytics"
              exact
              render={() => <Redirect to="/launchpad" />}
            />
            <Route
              path="/import-assign-users"
              exact
              render={() => <ImportWithUsers />}
            />
            <Route
              path="/create-assign-system"
              exact
              render={() => <CreateAssignSystem />}
            />
            <Route
              path="/policy-management"
              exact
              render={() => <PolicyList />}
            />
            <Route
              path="/policy-management/:id"
              exact
              render={() => <UpdatePolicy />}
            />
            <Route
              path="/create-policy"
              exact
              render={() => <CreatePolicy />}
            />
            <Route path="/role-management" exact render={() => <ListRoles />} />
            <Route path="/create-role" exact render={() => <CreateRole />} />
            <Route
              path="/role-management/:id"
              exact
              render={() => <UpdateRole />}
            />
            <Route path="/user-management" exact render={() => <ListUsers />} />
            <Route
              path="/user-management/add-user"
              exact
              render={() => <AddUser />}
            />
            <Route
              path="/user-management/:id"
              exact
              render={() => <UpdateUser />}
            />
            <Route
              path="/vendor/edit/:id"
              exact
              render={() => <CreateVendor />}
            />
            <Route
              path="/vendor/create"
              exact
              render={() => <CreateVendor />}
            />
            <Route
              path="/tenant-management/addTenant"
              exact
              render={() => <CreateTenant />}
            />
            <Route
              path="/table-access-control"
              exact
              render={() => <ObjectLevelSecurity />}
            />
            <Route
              path="/study-identifier-setup"
              exact
              render={() => <RowLevelSecurity />}
            />
            <Route
              path="/study-identifier-setup/suggested-tables"
              exact
              render={() => <SuggestedTables />}
            />
            <Route
              path="/group-management"
              exact
              render={() => <GroupManagement />}
            />
            <Route
              path="/group-management/add-new-group"
              exact
              render={() => <AddNewGroup />}
            />
            <Route
              path="/group-management/:id"
              exact
              render={() => <EditGroup />}
            />
            <Route
              path="/user-access-reports"
              exact
              render={() => <UserAccess />}
            />
            <Redirect from="/" to="/launchpad" />
          </Switch>
          {isFooterRequired && <AppFooter />}
        </div>
      ) : (
        <div className="page-wrapper">
          <Switch>
            <Route path="/checkAuthentication" exact render={() => <Empty />} />
            <Route
              path="/sda-failure"
              exact
              render={() => <SDAFailureScreen />}
            />
            <Route path="/logout" render={() => <Logout />} />
            <Redirect from="/" to="/checkAuthentication" />
          </Switch>
        </div>
      )}
    </Suspense>
  );
};

export default RoutesWrapper;
